import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 721.000000 535.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,535.000000) scale(0.100000,-0.100000)" >
				<path d="M7205 2675 c0 -1474 1 -2078 2 -1343 2 735 2 1941 0 2680 -1 739 -2
137 -2 -1337z"/>
<path d="M3445 3183 c-338 -53 -637 -272 -789 -578 -128 -257 -148 -547 -55
-814 135 -389 467 -656 886 -712 214 -28 469 31 679 157 92 56 262 219 317
306 148 233 205 487 167 753 -48 334 -275 640 -584 790 -150 73 -254 97 -436
100 -85 2 -168 1 -185 -2z m245 -33 c363 -38 656 -245 815 -575 73 -151 98
-260 98 -429 1 -283 -101 -529 -300 -724 -494 -483 -1309 -347 -1616 272 -160
323 -134 724 66 1016 31 44 47 63 116 142 92 104 289 220 450 264 157 43 225
49 371 34z"/>
<path d="M3410 3095 c-368 -74 -661 -358 -750 -725 -32 -133 -33 -320 -1 -440
39 -153 91 -261 179 -375 44 -58 190 -196 215 -204 7 -2 19 -9 27 -16 46 -40
173 -93 300 -127 127 -33 319 -31 452 5 336 91 583 325 686 647 62 193 56 430
-14 618 -88 233 -257 423 -469 529 -84 42 -198 80 -275 92 -88 14 -270 12
-350 -4z m159 -368 c75 -29 101 -72 101 -168 0 -94 -44 -156 -119 -166 -45 -6
-46 -18 -2 -28 39 -8 78 -52 89 -101 8 -35 6 -49 -15 -93 -20 -42 -25 -67 -24
-128 l1 -76 -47 7 c-54 7 -85 25 -110 63 -14 21 -19 56 -23 161 -5 126 -6 135
-29 153 -14 11 -39 19 -62 19 l-39 0 2 -182 3 -183 33 -3 c17 -2 32 -7 32 -13
0 -12 -347 -12 -355 0 -3 5 9 11 27 13 l33 3 3 363 2 362 -35 0 c-19 0 -35 5
-35 11 0 8 76 10 263 7 213 -4 270 -8 306 -21z m434 -453 c43 -11 67 -13 83
-6 20 10 22 7 28 -26 9 -58 7 -182 -4 -182 -5 0 -25 25 -44 55 -49 75 -85 111
-137 135 -52 23 -88 25 -133 6 -43 -18 -66 -51 -66 -96 0 -68 36 -96 178 -137
166 -48 232 -100 252 -200 26 -124 -31 -248 -135 -294 -68 -30 -127 -33 -252
-13 -112 17 -116 17 -129 -1 -8 -10 -16 -16 -19 -13 -11 10 -18 258 -8 258 6
0 26 -24 45 -52 78 -118 161 -177 246 -178 92 0 152 59 137 136 -10 54 -60 88
-189 129 -64 21 -133 50 -155 66 -50 38 -91 117 -91 178 0 102 66 204 153 235
56 19 165 20 240 0z"/>
<path d="M3297 2723 c-4 -3 -7 -80 -7 -170 l0 -163 30 0 c44 0 87 25 106 62
20 38 23 193 4 227 -18 35 -112 66 -133 44z"/>
<path d="M2578 843 c-26 -4 -41 -23 -18 -23 6 0 10 -32 10 -75 0 -58 -4 -77
-15 -81 -26 -10 -5 -24 36 -24 28 0 39 4 39 15 0 8 -4 15 -10 15 -5 0 -10 14
-10 30 0 33 17 41 27 13 10 -29 55 -73 75 -73 26 0 22 10 -17 50 -34 33 -45
60 -25 60 15 0 32 39 25 60 -5 17 -55 42 -79 39 -6 -1 -23 -4 -38 -6z m63 -23
c23 -13 25 -43 4 -60 -21 -18 -37 -8 -41 25 -5 44 4 52 37 35z"/>
<path d="M2823 842 c-65 -3 -93 -14 -62 -24 15 -5 15 -136 0 -151 -22 -22 -9
-27 68 -27 76 0 79 1 83 25 6 29 -9 45 -18 21 -4 -11 -19 -16 -45 -16 -36 0
-39 2 -39 29 0 37 19 50 36 27 7 -10 17 -15 23 -11 14 8 14 65 1 65 -5 0 -10
-4 -10 -10 0 -5 -11 -10 -25 -10 -21 0 -25 5 -25 30 0 28 3 30 35 30 25 0 35
-4 35 -16 0 -21 24 -12 28 10 6 27 -7 31 -85 28z"/>
<path d="M2943 839 c-17 -6 -17 -7 0 -14 24 -10 26 -150 1 -159 -34 -13 1 -26
70 -26 68 0 74 2 80 23 9 33 8 37 -9 37 -8 0 -15 -7 -15 -15 0 -11 -11 -15
-37 -15 l-38 0 3 75 c2 41 8 75 14 75 5 0 8 5 6 12 -5 13 -48 17 -75 7z"/>
<path d="M3153 842 c-18 -2 -33 -7 -33 -13 0 -5 4 -9 9 -9 5 0 21 -21 35 -46
27 -48 34 -98 16 -109 -22 -13 -8 -25 30 -25 38 0 52 12 30 25 -22 13 -10 73
24 124 19 28 33 51 30 52 -24 6 -58 1 -62 -9 -2 -7 1 -12 7 -12 7 0 7 -8 -1
-25 -14 -32 -32 -32 -46 -1 -8 19 -8 26 1 29 23 8 -7 22 -40 19z"/>
<path d="M3346 831 c-28 -31 -17 -72 27 -100 39 -26 48 -55 21 -65 -19 -7 -44
10 -44 30 0 8 -7 14 -15 14 -20 0 -19 -34 1 -54 34 -35 114 -3 114 45 0 22
-41 65 -72 75 -13 4 -18 13 -16 27 4 30 42 30 46 0 2 -13 7 -23 12 -23 4 0 10
10 14 23 11 43 -55 64 -88 28z"/>
<path d="M3477 838 c-8 -5 -6 -9 6 -14 14 -5 17 -19 17 -73 1 -79 14 -101 68
-108 34 -5 45 -1 66 20 23 23 26 35 26 90 0 50 4 66 16 71 25 10 9 21 -28 19
-53 -2 -54 -3 -35 -14 14 -8 17 -24 17 -79 0 -67 -1 -70 -27 -76 -43 -11 -58
6 -61 71 -2 32 -1 63 1 69 3 6 4 15 3 20 -1 11 -53 14 -69 4z"/>
<path d="M3738 843 c-30 -5 -37 -18 -14 -26 17 -7 15 -132 -3 -150 -20 -20
-11 -27 34 -27 57 0 110 22 117 47 6 22 -7 63 -21 63 -5 0 -5 12 2 30 9 24 9
33 -4 45 -17 17 -68 25 -111 18z m84 -41 c8 -28 -2 -42 -28 -42 -20 0 -24 5
-24 28 0 16 3 31 6 34 10 11 42 -4 46 -20z m11 -83 c16 -26 1 -49 -33 -49 -28
0 -30 3 -30 36 0 32 2 35 26 32 15 -2 32 -10 37 -19z"/>
<path d="M4043 838 c-52 -25 -67 -128 -25 -171 29 -29 92 -33 122 -9 28 23 22
39 -8 23 -54 -29 -102 4 -102 69 0 74 70 110 93 48 10 -28 27 -21 27 11 0 21
-6 30 -22 35 -32 8 -59 6 -85 -6z"/>
<path d="M4240 837 c-14 -7 -33 -29 -44 -51 -44 -90 38 -176 129 -134 60 27
73 116 26 167 -32 34 -68 40 -111 18z m80 -39 c26 -43 26 -92 0 -118 -23 -23
-39 -25 -65 -6 -26 19 -34 116 -10 139 26 26 53 21 75 -15z"/>
<path d="M4615 840 c-25 -4 -33 -14 -50 -55 -11 -28 -24 -53 -28 -55 -5 -3
-18 20 -30 50 -21 53 -24 55 -62 58 -37 2 -39 1 -21 -13 17 -12 18 -23 12 -82
-4 -41 -13 -72 -21 -79 -20 -15 -8 -24 31 -24 36 0 55 17 30 26 -11 4 -16 19
-16 44 0 58 14 55 35 -6 10 -30 24 -54 30 -54 12 0 55 87 55 110 0 6 5 8 10 5
6 -3 10 -26 10 -50 0 -24 -4 -47 -10 -50 -22 -13 -8 -25 30 -25 40 0 49 7 30
26 -17 17 -25 144 -10 154 22 13 6 26 -25 20z"/>
<path d="M3915 670 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
